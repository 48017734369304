import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="about" id="about">
      <h2 className="main-title">About</h2>
      <div className="container">
        <div className="my-info">
          <h2>
            <span>Name:</span> Ahmed Said Adnan
          </h2>
          <h2>
            <span>Birthdate:</span> 12/10/1996
          </h2>
          <h2>
            <span>Phone:</span>{" "}
            <a href="tel:01099812036" className="my-number" title="Call 01099812036">
              01099812036
            </a>
          </h2>
          <h2>
            <span>Email:</span>{" "}
            <a href="mailto:ahmedsaidadnan@outlook.com" className="my-email" title="Send an email">
              ahmedsaidadnan@outlook.com
            </a>
          </h2>
          <h2>
            <span>Address:</span> Al Haram, Giza
          </h2>
          <h2>
            <span>Military Status:</span> Exempted
          </h2>
          <h2>
            <span>Freelance:</span> Available
          </h2>
        </div>
        <div className="education">
          <h2>
            <span>Education:</span> Law Bachelor's Degree
          </h2>
          <h2>
            <span>University:</span> Cairo University
          </h2>
          <h2>
            <ul>
              <span className="certificates">Certificates:</span>
              <li>
                <a
                  href="https://s3-us-west-2.amazonaws.com/udacity-printer/production/certificates/8457dc25-2462-4eed-8f03-999f9d371821.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Front-End Web Development Challenger Track Certification by Udacity | 2021
                </a>
              </li>
              <li>
                <a
                  href="https://graduation.udacity.com/confirm/KNQ4GMMJ"
                  target="_blank"
                  rel="noreferrer"
                >
                  Professional Front-End Web Development Certification by Udacity | 2022
                </a>
              </li>
              <li>
                <a
                  href="https://www.sololearn.com/certificates/CT-WAGUBDH0"
                  target="_blank"
                  rel="noreferrer"
                >
                  HTML Certification by Sololearn | 2022
                </a>
              </li>
              <li>
                <a
                  href="https://www.sololearn.com/certificates/CT-QV1WDUS7"
                  target="_blank"
                  rel="noreferrer"
                >
                  CSS Certification by Sololearn | 2022
                </a>
              </li>
              <li>
                <a
                  href="https://api.badgr.io/public/assertions/iDdgQq3vSi2k53DTRwerZQ?identity__email=ahmedsaidadnan%40outlook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  English for Career Development MOOC by OPEN Online Professional English Network |
                  2022
                </a>
              </li>
              <li>
                <a
                  href="https://graduation.udacity.com/api/graduation/certificate/CFLCSCUD/download"
                  target="_blank"
                  rel="noreferrer"
                >
                  React Development Cross-Skilling Track Certification by Udacity | 2022
                </a>
              </li>
            </ul>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default About;
