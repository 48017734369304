import React, { useEffect, useRef, useState } from "react";
import "./Skills.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faLightbulb, faLaptop } from "@fortawesome/free-solid-svg-icons";
import { faCodepen } from "@fortawesome/free-brands-svg-icons";

const Skills = () => {
  const myRef = useRef();
  const [htmlW, setHtmlW] = useState({});
  const [cssW, setCssW] = useState({});
  const [jsW, setJsW] = useState({});
  const [bsW, setBsW] = useState({});
  const [sassW, setSassW] = useState({});
  const [reactW, setReactW] = useState({});
  const [tsW, setTsW] = useState({});
  const [isVisible, setIsVisible] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsVisible(entry.isIntersecting);
    });
    observer.observe(myRef.current);
    if (isVisible) {
      setHtmlW({ width: "90%" });
      setCssW({ width: "90%" });
      setJsW({ width: "85%" });
      setBsW({ width: "75%" });
      setSassW({ width: "70%" });
      setReactW({ width: "85%" });
      setTsW({ width: "75%" });
    } else {
      setHtmlW({ width: "0" });
      setCssW({ width: "0" });
      setJsW({ width: "0" });
      setBsW({ width: "0" });
      setSassW({ width: "0" });
      setReactW({ width: "0" });
      setTsW({ width: "0" });
    }
  }, [isVisible]);

  return (
    <div className="skills-section" id="skills">
      <h2 className="main-title">Skills</h2>
      <div className="container">
        <div className="box-wrapper">
          <div className="box">
            <div className="icon-wrap">
              <div className="hexagon bolt">
                <FontAwesomeIcon className="icon" icon={faBolt} />
              </div>
            </div>
            <div className="text">
              <div className="label">Fast</div>
              <div>Fast load times and lag free interaction, my highest priority.</div>
            </div>
          </div>
          <div className="box">
            <div className="icon-wrap">
              <div className="hexagon laptop">
                <FontAwesomeIcon className="icon" icon={faLightbulb} />
              </div>
            </div>
            <div className="text">
              <div className="label">Intuitive</div>
              <div>Strong preference for easy to use, intuitive UX/UI.</div>
            </div>
          </div>
          <div className="box">
            <div className="icon-wrap">
              <div className="hexagon lightbulb">
                <FontAwesomeIcon className="icon" icon={faLaptop} />
              </div>
            </div>
            <div className="text">
              <div className="label">Responsive</div>
              <div>My layouts will work on any device, big or small.</div>
            </div>
          </div>
          <div className="box">
            <div className="icon-wrap">
              <div className="hexagon dynamic">
                <FontAwesomeIcon className="icon" icon={faCodepen} />
              </div>
            </div>
            <div className="text">
              <div className="label">Dynamic</div>
              <div>Websites don't have to be static, I love making pages come to life.</div>
            </div>
          </div>
        </div>
        <div className="our-skills">
          <div className="container">
            <div className="skills" ref={myRef}>
              {/* div */}
              <div className="skill html-skill">
                <h3>
                  HTML <span>90%</span>
                </h3>
                <div className="progress">
                  <span style={htmlW} className="html"></span>
                </div>
              </div>
              <div className="skill css-skill">
                <h3>
                  CSS <span>90%</span>
                </h3>
                <div className="progress">
                  <span style={cssW} className="css"></span>
                </div>
              </div>
              <div className="skill js-skill">
                <h3>
                  JavaScript <span>85%</span>
                </h3>
                <div className="progress">
                  <span style={jsW} className="js"></span>
                </div>
              </div>
              {/* div */}
              {/* div */}
              <div className="skill bootstrap-skill">
                <h3>
                  Bootstrap <span>75%</span>
                </h3>
                <div className="progress">
                  <span style={bsW} className="bootstrap"></span>
                </div>
              </div>
              <div className="skill sass-skill">
                <h3>
                  Sass <span>70%</span>
                </h3>
                <div className="progress">
                  <span style={sassW} className="sass"></span>
                </div>
              </div>
              <div className="skill react-skill">
                <h3>
                  React <span>85%</span>
                </h3>
                <div className="progress">
                  <span style={reactW} className="react"></span>
                </div>
              </div>
              <div className="skill ts-skill">
                <h3>
                  TypeScript <span>75%</span>
                </h3>
                <div className="progress">
                  <span style={tsW} className="ts"></span>
                </div>
              </div>
              {/* div */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
