import "./Projects.css";

function ProjectItem({ imageUrl, title, preview, repo }) {
  return (
    <div className="box">
      <img src={imageUrl} alt={title + "-image"} loading="lazy" />
      <div className="caption">
        <h4>{title}</h4>
        <div className="details-holder">
          <a href={preview} target="_blank" rel="noreferrer">
            View
          </a>
          <a href={repo} target="_blank" rel="noreferrer">
            Code
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProjectItem;
