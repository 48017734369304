import React, { useEffect, useState } from "react";
import "./Navbar.css";

const Navbar = () => {
  let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  const [scrollWidth, setScrollWidth] = useState();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = document.documentElement.scrollTop;
      setScrollWidth((scrollTop / height) * 100 + "%");
    });
  }, [height]);

  return (
    <div className="header">
      <div
        className="scroller"
        style={{ width: scrollWidth }}
      ></div>
      <div className="container">
        <div className="logo">Ahmed Said</div>
        <ul className="main-nav">
          <li>
            <a href="/#intro">intro</a>
          </li>
          <li>
            <a href="/#about">about</a>
          </li>
          <li>
            <a href="/#skills">Skills</a>
          </li>
          <li>
            <a href="/#projects">Projects</a>
          </li>
          <li>
            <a href="/#contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;


