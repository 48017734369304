import React from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  // faTwitter,
  faLinkedin,
  faGithub,
  faWhatsapp,
  // faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  return (
    <div className="contact-section" id="contact">
      <h2 className="main-title">Contact</h2>
      <div className="container">
        <ul className="social">
          {/* <li>
            <a
              href="https://www.facebook.com/ahmedsaid9610/"
              target="_blank"
              className="facebook"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} className="icon" />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/AhmedSaid9610"
              target="_blank"
              className="twitter"
              rel="noreferrer"
            >
              <i
                icon="fa-brands fa-twitter"
                className="fa-brands fa-x-twitter icon"
              />
            </a>
          </li> */}
          <li>
            <a
              href="https://www.linkedin.com/in/ahmedsaidadnan/"
              target="_blank"
              rel="noreferrer"
              className="linkedin"
            >
              <FontAwesomeIcon icon={faLinkedin} className="icon" />
            </a>
          </li>
          <li>
            <a
              href="https://github.com/AhmeddSaid"
              target="_blank"
              rel="noreferrer"
              className="github"
            >
              <FontAwesomeIcon icon={faGithub} className="icon" />
            </a>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=201099812036"
              className="whatsapp"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faWhatsapp} className="icon" />
            </a>
          </li>
          <li>
            <a href="mailto:ahmedsaidadnan@outlook.com" className="email">
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
            </a>
          </li>
          {/* <li>
            <a href="tel:01099812036" className="phone">
              <FontAwesomeIcon icon={faPhone} className="icon" />
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Contact;
